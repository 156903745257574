import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/architecture/Hero"
import Intro from "../components/architecture/Intro"
import SliderLightbox from "../components/architecture/SliderLightbox"
import FeaturedImage from "../components/architecture/FeaturedImage"
import MoreInformationLinks from "../components/architecture/MoreInformationLinks"

const Architecture = props => {
  const { seoInfo } = props.data
  const hero = props?.data?.hero?.template?.architecture
  const intro = props?.data?.intro?.template?.architecture
  const sliderLightbox = props?.data?.sliderLightbox?.template?.architecture
  const featuredImg = props?.data?.featuredImg?.template?.architecture
  const moreImformation = props?.data?.moreImformation?.template?.architecture

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={hero} />
      <Intro data={intro} />
      <SliderLightbox data={sliderLightbox} />
      <FeaturedImage data={featuredImg} />
      <MoreInformationLinks data={moreImformation} />
    </Layout>
  )
}

export const architectureQuery = graphql`
  query architectureTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Architecture {
          architecture {
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    intro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Architecture {
          architecture {
            introTitle
            introBigContent
            introMainContent
          }
        }
      }
    }

    sliderLightbox: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Architecture {
          architecture {
            sliderSlides {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 2500)
                  }
                }
              }
            }
          }
        }
      }
    }

    featuredImg: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Architecture {
          architecture {
            featuredImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    moreImformation: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Architecture {
          architecture {
            moreInformationLinksTitle
            moreInformationLinksButtons {
              buttonText
              linkType
              page {
                ... on WpPage {
                  id
                  slug
                  uri
                }
              }
              url
              sectionId
            }
          }
        }
      }
    }
  }
`

export default Architecture
